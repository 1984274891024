<template>
  <v-main>
    <bar />
    <v-container class="container overflow-y-auto">
      <video-header-section
        v-if="contentInfos.videoHeaderSection"
        :client="client"
        :content-infos="contentInfos"
        @scroll-down="scrollToElement()"
      ></video-header-section>
      <text-section
        id="text-section"
        v-if="contentInfos.textSection"
        :client="client"
        :content-infos="contentInfos"
      ></text-section>
      <info-section
        id="info-section"
        v-if="contentInfos.infoSection"
        :client="client"
        :content-infos="contentInfos"
      ></info-section>
      <unique-services-section
        v-if="contentInfos.uniqueServicesSection"
        id="feature-section"
        :client="client"
        :content-infos="contentInfos"
      ></unique-services-section>
      <what-we-do-section
        v-if="contentInfos.whatDoWeDo"
        :client="client"
        :content-infos="contentInfos"
      ></what-we-do-section>
      <image-comparison-section
        v-if="contentInfos.imageCompareSection"
        :client="client"
        :content-infos="contentInfos"
      ></image-comparison-section>
      <user-quotes
        v-if="contentInfos.userQuotes"
        :client="client"
        :content-infos="contentInfos"
      >
      </user-quotes>
      <subscription-table
        v-if="contentInfos.subscriptionTable"
        :client="client"
        :content-infos="contentInfos"
      ></subscription-table>
      <partners-section
        v-if="contentInfos.partners"
        :client="client"
        :content-infos="contentInfos"
      ></partners-section>
      <footer-section></footer-section>
    </v-container>
  </v-main>
</template>

<script>
// import AppConfig from "@/app/config";
import Bar from "@/core/components/welcomeView/bar.vue";
import VideoHeaderSection from "@/core/components/welcomeView/VideoHeaderSection.vue";
import WhatWeDoSection from "@/core/components/welcomeView/WhatWeDoSection.vue";
import InfoSection from "@/core/components/welcomeView/InfoSection.vue";
import FooterSection from "@/core/components/welcomeView/FooterSection.vue";
import ImageComparisonSection from "@/core/components/welcomeView/ImageComparisonSection.vue";
import TextSection from "@/core/components/welcomeView/TextSection.vue";
import PartnersSection from "@/core/components/welcomeView/PartnersSection.vue";
import UserQuotes from "@/core/components/welcomeView/UserQuotes.vue";
import SubscriptionTable from "@/core/components/welcomeView/SubscriptionTable.vue";
import UniqueServicesSection from "@/core/components/welcomeView/UniqueServicesSection.vue";

export default {
  name: "WelcomePage",
  components: {
    UniqueServicesSection,
    SubscriptionTable,
    UserQuotes,
    PartnersSection,
    TextSection,
    ImageComparisonSection,
    FooterSection,
    InfoSection,
    WhatWeDoSection,
    VideoHeaderSection,
    Bar
  },
  computed: {
    client() {
      return this.$appConfig;
    },
    contentInfos() {
      switch (this.$appConfig.name) {
        case "hypos":
          return require("/src/core/assets/hypos/welcomePageInfos.json");
        case "xylem":
          return require("/src/core/assets/eoportal/welcomePageInfos.json");
        case "seaweed":
          return require("/src/core/assets/eoportal/welcomePageInfos.json");
        case "coasts":
          return require("/src/core/assets/coasts/welcomePageInfos.json");
        default:
          return require("/src/core/assets/eoportal/welcomePageInfos.json");
      }
    }
  },
  methods: {
    scrollToElement() {
      let id = "";
      if (this.$appConfig.keycloakClient !== "coasts") {
        id = "info-section";
      } else {
        id = "text-section";
      }
      const el = document.getElementById(id);
      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
      }
    }
  }
};
</script>

<style scoped>
.container {
  max-width: 100% !important;
  padding: 0px;
  height: 95vh;
}
</style>
